import TypeDateTime from '@/model/type/TypeDateTime';
import Priv from '@/model/user/Priv';
import UserID from '@/model/user/UserID';
import UserName from '@/model/user/UserName';
import UserBaseInfo from '@/model/user/UserBaseInfo';
import DataType, { COMMENT } from '../DataType';
import CommentId from './CommentId';
import CommentMessage from './CommentMessage';
import PostId from '../PostId';

export default class CommentBody {
  /** 投稿ID */
  commentId: CommentId;

  /** 投稿区分 */
  dataType: DataType;

  /** 投稿者ID */
  registerId: UserID;

  /** 投稿者名 */
  registerName: UserName;

  /** 投稿者権限 */
  registerPriv: Priv;

  /** 公開日時 */
  openDate: TypeDateTime;

  /** 本文 */
  comment: CommentMessage;

  /** 元投稿ID */
  originPostId: PostId;

  constructor(
    commentId?: CommentId,
    dataType?: DataType,
    registerId?: UserID,
    registerName?: UserName,
    registerPriv?: Priv,
    openDate?: TypeDateTime,
    comment?: CommentMessage,
    originPostId?: PostId
  ) {
    this.commentId = (commentId === undefined ? new CommentId() : commentId);
    this.dataType = (dataType === undefined ? new DataType() : dataType);
    this.registerId = (registerId === undefined ? new UserID() : registerId);
    this.registerName = (registerName === undefined ? new UserName() : registerName);
    this.registerPriv = (registerPriv === undefined ? new Priv() : registerPriv);
    this.openDate = (openDate === undefined ? new TypeDateTime() : openDate);
    this.comment = (comment === undefined ? new CommentMessage() : comment);
    this.originPostId = (originPostId === undefined ? new PostId() : originPostId);
  }
  static create(commentId: CommentId, user: UserBaseInfo, message: CommentMessage, originPostId: PostId): CommentBody {
    return new CommentBody(commentId, COMMENT, user.userId, user.username, user.priv, new TypeDateTime(), message, originPostId);
  }

  static clone(source: CommentBody): CommentBody {
    const result = new CommentBody();
    result.commentId = CommentId.clone(source.commentId);
    result.dataType = DataType.clone(source.dataType);
    result.registerId = UserID.clone(source.registerId);
    result.registerName = UserName.clone(source.registerName);
    result.registerPriv = Priv.clone(source.registerPriv);
    result.openDate = TypeDateTime.clone(source.openDate);
    result.comment = CommentMessage.clone(source.comment);
    result.originPostId = PostId.clone(source.originPostId);
    return result;
  }
}


import { Vue, Component, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import { NavigationGuardNext, Route } from 'vue-router';
import InputJpDate from '@/components/Parts/InputJpDate.vue';
import VacationInfo from '@/model/posts/vacation/VacationInfo';
import VacationRequestApi from '@/module/api/VacationRequestApi';
import { ErrorResponseModel } from '@/model/StaffManager';
import { DRAFT, PUBLIC } from '@/model/posts/PostStatus';
import PostBody from '@/model/posts/PostBody';
import { INFO, VACATION } from '@/model/posts/DataType';
import PostId from '@/model/posts/PostId';
import BackButton from '@/components/Parts/BackButton.vue';

Component.registerHooks([
  'beforeRouteEnter'
]);
@Component({
  components: {
    InputJpDate,
    BackButton
  }
})
export default class VacationRequest extends Vue {
  postBody:PostBody = new PostBody();
  vacationInfo:VacationInfo = new VacationInfo();
  postId!:PostId;
  isRunning = false;
  hasBefore = false;

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<VacationRequest>): void {
    next((vm: VacationRequest) => vm.initialize(to));
  }
  initialize(to: Route): void {
    if (to.params.userId) {
      this.hasBefore = true;
    }
  }
  mounted():void{
    if (this.$route.params.postId !== undefined) {
      this.hasBefore = true;
      VacationRequestApi.getDruft(this.$route.params.postId).then((res) => {
        this.vacationInfo = VacationInfo.clone(res.data);
      });
    }
  }

  /**
   * 登録メイン処理
   */
  save(message:string):void{
    this.$nextTick();
    this.postBody.postId = this.vacationInfo.postId;
    this.postBody.dataType = VACATION;
    this.postBody.postTitle.value = '休暇連絡';
    if (this.vacationInfo.startTime.chekTime() && this.vacationInfo.endTime.chekTime()) {
      VacationRequestApi.post(this.vacationInfo, this.postBody).then(() => {
        this.$root.$emit('event-show-snackbar', message);
        this.$router.push('/informationList');
      }).catch((error:AxiosError<ErrorResponseModel>) => {
        if (error.response?.data.code === 'ValueInstantiation') {
          this.$root.$emit('event-show-snackbar-infinity', error.response?.data.detail);
          return;
        }
        if (error.response?.data.code === 'DateTime') {
          this.$root.$emit('event-show-snackbar-infinity', error.response?.data.detail);
          return;
        }
        this.$root.$emit('event-show-snackbar-infinity', '予期しないエラーが発生しました。');
      }).finally(() => {
        this.isRunning = false;
      });
    } else {
      this.$root.$emit('event-show-snackbar-infinity', '時間の入力に誤りがあります。分は0~59で入力してください。');
    }
  }

  /**
  * 公開保存
  */
  post():void{
    if (this.isRunning) {
      return;
    }
    this.isRunning = true;
    this.$nextTick();
    this.postBody.status = PUBLIC;
    this.save('送信しました。');
  }

  /**
  * 下書き保存
  */
  postAsDraft():void{
    this.isRunning = true;
    this.postBody.status = DRAFT;
    this.save('下書きを保存しました。');
  }
}

import { AxiosPromise } from 'axios';
import VacationInfo from '@/model/posts/vacation/VacationInfo';
import PostBody from '@/model/posts/PostBody';
import PostId from '@/model/posts/PostId';
import axios from '../AxiosBase';

export default {

  // 休暇連絡情報取得 & 既読
  getViewer(postId:PostId):AxiosPromise<any> {
    return axios.get(`vacation/viewer/${postId.value}`);
  },
  getDruft(postId:string):AxiosPromise<any> {
    return axios.get(`vacation/druft/${postId}`);
  },

  post(vacationInfo:VacationInfo, body:PostBody):AxiosPromise<any> {
    return axios.post('vacation/post', { vacationInfo, body });
  },

  confirmed(postId:PostId):AxiosPromise<any> {
    return axios.post('vacation/confirmed', postId);
  },

  findConfirmedUser(postId:string):AxiosPromise<any> {
    return axios.get(`vacation/findConfirmedUser/${postId}`);
  }
};

export default class Remarks {
  value:string;

  constructor(value?:string) {
    this.value = (value === undefined ? '' : value);
  }
  public static clone(source:Remarks):Remarks {
    const result = new Remarks();
    result.value = source.value;
    return result;
  }
}

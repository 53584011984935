export default class CommentId {
  value: number;

  constructor(value?: number) {
    this.value = (value === undefined ? -1 : value);
  }

  public isEmpty():boolean {
    if (!this.value) return true;
    return this.value === -1;
  }

  public static empty(): CommentId {
    return new CommentId(-1);
  }

  static clone(source: CommentId): CommentId {
    const result = new CommentId();
    result.value = source.value;
    return result;
  }
}

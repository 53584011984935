import TypeJpDate from './TypeJpDate';

export default class TypeJpDateTime {
  date: TypeJpDate;
  time: string;

  constructor(
    date?: TypeJpDate,
    time?: string
  ) {
    this.date = date || new TypeJpDate();
    this.time = time || '';
  }

  toString():string {
    if (this.time === '') return '-';
    return `${this.date.toString()} ${this.time}`;
  }
  public static clone(source: TypeJpDateTime): TypeJpDateTime {
    const result = new TypeJpDateTime();
    result.date = TypeJpDate.clone(source.date);
    result.time = source.time;
    return result;
  }
}

export default class TypeTime {
  hour:number;
  minute:number;

  constructor(
    hour?:number,
    minute?:number
  ) {
    this.hour = (hour === undefined ? 0 : hour);
    this.minute = (minute === undefined ? 0 : minute);
  }
  chekTime():boolean {
    if ((this.hour >= 0 && this.hour <= 23) &&
    (this.minute >= 0 && this.minute <= 59)) {
      return true;
    }
    return false;
  }
  public static clone(source:TypeTime):TypeTime {
    const result = new TypeTime();
    result.hour = source.hour;
    result.minute = source.minute;
    return result;
  }
}

import TypeJpDate from '@/model/type/TypeJpDate';
import TypeTime from '@/model/type/TypeTime';
import UserName from '@/model/user/UserName';
import CommentDetail from '../comment/CommentDetail';
import PostId from '../PostId';
import Remarks from './Remarks';

export default class VacationInfo {
  postId: PostId;
  startDate: TypeJpDate;
  endDate: TypeJpDate;
  startTime: TypeTime;
  endTime: TypeTime;
  remarks: Remarks;
  comment: CommentDetail[];
  registerName: UserName;

  constructor(
    postId?: PostId,
    startDate?: TypeJpDate,
    endDate?: TypeJpDate,
    startTime?: TypeTime,
    endTime?: TypeTime,
    remarks?: Remarks,
    comment?: CommentDetail[],
    registerName?: UserName,
  ) {
    this.postId = (postId === undefined ? new PostId() : postId);
    this.startDate = (startDate === undefined ? TypeJpDate.getToday() : startDate);
    this.endDate = (endDate === undefined ? TypeJpDate.getToday() : endDate);
    this.startTime = (startTime === undefined ? new TypeTime() : startTime);
    this.endTime = (endTime === undefined ? new TypeTime() : endTime);
    this.remarks = (remarks === undefined ? new Remarks() : remarks);
    this.comment = (comment === undefined ? [] : comment);
    this.registerName = (registerName === undefined ? new UserName() : registerName);
  }

  public static clone(source: VacationInfo): VacationInfo {
    const result = new VacationInfo();
    result.postId = PostId.clone(source.postId);
    result.startDate = TypeJpDate.clone(source.startDate);
    result.endDate = TypeJpDate.clone(source.endDate);
    result.startTime = TypeTime.clone(source.startTime);
    result.endTime = TypeTime.clone(source.endTime);
    result.remarks = Remarks.clone(source.remarks);
    result.comment = CommentDetail.cloneList(source.comment);
    result.registerName = UserName.clone(source.registerName);
    return result;
  }
}

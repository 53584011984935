import UserBaseInfo from '@/model/user/UserBaseInfo';
import TypeJpDateTime from '@/model/type/TypeJpDateTime';
import TypeJpDate from '@/model/type/TypeJpDate';
import CommentBody from './CommentBody';
import CommentId from './CommentId';
import CommentMessage from './CommentMessage';
import PostId from '../PostId';

export default class CommentDetail {
  /** コメント */
  body: CommentBody;

  // /** コメントID */
  // commentId: CommentId;

  // /** 投稿者ID */
  // registerId: UserID;

  // /** 投稿者名 */
  // registerName: UserName;

  // /** コメント */
  // comment: CommentMessage;

  // /** 公開日時 */
  // openDate: TypeDateTime;

  /** 閲覧可能か */
  commentCanRead: boolean;

  /** 存在チェック */
  isEmpty: boolean;

  /** 既読判定 */
  isReaded: boolean;

  /** 子コメント */
  childComment: CommentDetail[];

  /** 投稿日時 */
  readOpenDate: TypeJpDateTime;

  constructor(
    body?: CommentBody,
    commentCanRead?: boolean,
    isEmpty?: boolean,
    isReaded?: boolean,
    childComment?: CommentDetail[],
    readOpenDate?: TypeJpDateTime
  ) {
    this.body = (body === undefined ? new CommentBody() : body);
    this.commentCanRead = (commentCanRead === undefined ? false : commentCanRead);
    this.isEmpty = (isEmpty === undefined ? false : isEmpty);
    this.isReaded = (isReaded === undefined ? false : isReaded);
    this.childComment = (childComment === undefined ? [] : childComment);
    this.readOpenDate = (readOpenDate === undefined ? new TypeJpDateTime() : readOpenDate);
  }
  static create(commentId: CommentId, userBaseInfo: UserBaseInfo, commentMessage: CommentMessage, originPostId: PostId): CommentDetail {
    const body = CommentBody.create(commentId, userBaseInfo, commentMessage, originPostId);
    const now = new Date();

    // const date = TypeJpDate.getToday().toString();

    // 時
    let hour = now.getHours().toString();
    if (hour.length < 2) {
      hour = `0${hour}`;
    }

    // 分
    let min = now.getMinutes().toString();
    if (min.length < 2) {
      min = `0${min}`;
    }

    // 秒
    let sec = now.getSeconds().toString();
    if (sec.length < 2) {
      sec = `0${sec}`;
    }

    const time = `${hour}:${min}:${sec}`;

    return new CommentDetail(body, true, false, false, [], new TypeJpDateTime(TypeJpDate.getToday(), time));
  }
  static cloneList(source: CommentDetail[]): CommentDetail[] {
    return source.map((s) => CommentDetail.clone(s));
  }
  static clone(source: CommentDetail): CommentDetail {
    const result = new CommentDetail();
    result.body = CommentBody.clone(source.body);
    result.commentCanRead = source.commentCanRead;
    result.isEmpty = source.isEmpty;
    result.isReaded = source.isReaded;
    result.childComment = CommentDetail.cloneList(source.childComment);
    result.readOpenDate = TypeJpDateTime.clone(source.readOpenDate);
    return result;
  }
}
